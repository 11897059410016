<template>
  <v-hover 
    v-slot="{ hover }"
    open-delay="250"
    v-intersect="checkInView"
  >
    <v-lazy
      :value="isInView"
      :options="{
        threshold: .125
      }"
      :min-height="minHeight"
      transition="fade-transition"
    >
      <v-card
        flat
        :loading="waiting&&loading"
        :max-height="maxHeight"
        color="grey darken-3"
        class="gallery-card rounded-lg"
        :class="{ highlight: controller.highlight }"
        @drop.prevent="file($event, true)"
        @dragover.prevent="highlight(true)"
        @dragenter="highlight(true)"
        @dragleave="highlight(false)"
        @click="open(true)"
      >
        <v-overlay
          v-if="empty"
          color="white"
          :opacity="hover ? .25 : 0"
        >
          <div 
            class="d-flex flex-column align-center text-center"
          >
            <v-btn
              fab
              icon
              x-large
              :loading="controller.upload.loading"
              color="white"
              class="btn my-4"
              @click.stop="select"
            >
              <v-icon large>
                {{ icons.up }}
              </v-icon>
            </v-btn> 
            <p class="text-overline">
              {{ $t('dashboard.gallery.'+(controller.upload.loading ? 'sending' : 'send')) }}
            </p>
          </div>
        </v-overlay>
        <gallery-player
          :url="media==null ? null : media.url"
          :square="square"
          :in-view="ready&&isInView"
          :autoplay="isInView&&autoplay"
          :max-height="maxHeight"
          :min-height="minHeight"
          rounded
          @ready="onReady"
        />
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-fab-transition>
              <v-btn
                v-show="media.status===2"
                fab
                absolute
                top
                left
                icon
                small
                color="accent"
                class="mt-6 ml-n3"
                v-bind="attrs"
                v-on="on"
                @click.stop
              >
                <v-icon>
                  {{ icons.star }}
                </v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span>{{ $t('dashboard.gallery.starred_media') }}</span>
        </v-tooltip>
        <v-slide-y-reverse-transition>
          <v-footer
            v-if="admin&&(approving||hover||media.status===null)&&!empty"
            class="d-flex pa-4"
            absolute
            color="transparent"
          >
            <v-spacer />
            <v-card 
              class="mx-2"
              :elevation="media.status==null ? 8 : 0"
            >
              <m-btn-long
                :show="media.status===0"
                depressed
                text
                tile
                :icon="icons.delete"
                :loading="controller.remove.loading"
                :duration="controller.remove.duration"
                :tooltip="{ ...controller.remove.tooltip, text: $t(controller.remove.tooltip.text) }"
                color="grey"
                press-color="error"
                min-width="36"
                content-class="px-0 rounded-l"
                class="btn"
                @cancel="remove(false)"
                @press="remove(true)"
              />
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    :icon="media.status!==0"
                    tile
                    :loading="controller.reject.loading"
                    :color="(media.status===0 ? 'error' : 'grey')"
                    min-width="36"
                    class="btn px-0"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="approve(0)"
                  >
                    <v-icon>
                      {{ icons.off }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('dashboard.gallery.reject') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    :icon="media.status!==1"
                    tile
                    :loading="controller.approve.loading"
                    :color="(media.status===1 ? 'success' : 'grey')"
                    min-width="36"
                    class="btn px-0"
                    :class="{ 'rounded-r': media.status!==1 }"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="approve(1)"
                  >
                    <v-icon>
                      {{ icons.check }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('dashboard.gallery.approve') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="media.status!==0"
                    depressed
                    :icon="media.status!==2"
                    tile
                    :loading="controller.star.loading"
                    :color="(media.status===2 ? 'accent' : 'grey')"
                    min-width="36"
                    class="btn px-0 rounded-r"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="approve(2)"
                  >
                    <v-icon>
                      {{ icons.star }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('dashboard.gallery.star') }}</span>
              </v-tooltip>
            </v-card>
            <v-spacer />
          </v-footer>
        </v-slide-y-reverse-transition>
        <v-speed-dial
          v-if="admin"
          absolute
          bottom
          right
          direction="top"
          open-on-hover
          @click.native.stop
        >
          <template v-slot:activator>
            <v-slide-x-reverse-transition>
              <v-btn
                v-show="hover&&!empty"
                fab
                small
                color="grey lighten-4"
                @click.stop
              >
                <v-icon>
                  {{ icons.menu }}
                </v-icon>
              </v-btn>
            </v-slide-x-reverse-transition>
          </template>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                color="grey lighten-4"
                :loading="controller.download.loading"
                class="btn"
                v-bind="attrs"
                v-on="on"
                @click.stop="download"
              >
                <v-icon>
                  {{ icons.down }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('dashboard.gallery.download') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                color="grey lighten-4"
                :loading="controller.upload.loading"
                class="btn"
                v-bind="attrs"
                v-on="on"
                @click.stop="select"
              >
                <v-icon>
                  {{ icons.up }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('dashboard.gallery.upload') }}</span>
          </v-tooltip>
        </v-speed-dial>
        <v-slide-x-reverse-transition>
          <v-btn
            v-if="!admin&&!empty&&hover"
            fab
            absolute
            bottom
            right
            small
            color="grey lighten-4"
            :loading="controller.download.loading"
            class="btn mb-8"
            @click.stop="download"
          >
            <v-icon>
              {{ icons.down }}
            </v-icon>
          </v-btn>
        </v-slide-x-reverse-transition>
        <input
          type="file"
          :value="controller.upload.url"
          multiple
          ref="upload-field"
          class="d-none"
          @change="file($event, false)"
        />
        <div class="temp">
          <video ref="video" muted="muted" />
          <img ref="image" />
        </div>
      </v-card>
    </v-lazy>
  </v-hover>
</template>

<style>

  .gallery-card {
    overflow: hidden;
    filter: drop-shadow(0px 4px 12px rgba(61, 75, 143, 0.16));
  }

  .gallery-card .temp {
    position: fixed;
    left: 9999999px;
    visibility: hidden;
  }

  .gallery-card .v-progress-circular__overlay {
    transition-property: none !important;
  }


</style>

<script>
  import { 
    mdiCheck,
    mdiDeleteOutline,
    mdiDownload,
    mdiUpload,
    mdiClose,
    mdiDotsVertical,
    mdiStar
  } from '@mdi/js';

  export default {
    name: 'GalleryCard',

    props: {
      media: {
        type: Object,
        default: () => {
          return {
            url: null
          }
        },
      },
      loading: {
        type: Boolean,
        default: false
      },
      autoplay: {
        type: Boolean,
        default: true
      },
      square: {
        type: Boolean,
        default: true
      },
      zoomed: {
        type: Boolean,
        default: true
      },
      admin: {
        type: Boolean,
        default: false
      },
      maxHeight: {
        type: [String, Number],
        default: '80vh'
      },
      minHeight: {
        type: [String, Number],
        default: '25vw'
      },
    },

    data: () => ({
      icons: {
        check: mdiCheck,
        delete: mdiDeleteOutline,
        down: mdiDownload,
        up: mdiUpload,
        off: mdiClose,
        menu: mdiDotsVertical,
        star: mdiStar
      },
      controller: {
        remove: {
          duration: 2000,
          tooltip: {
            text: 'general.delete',
            top: true,
            disabled: false,
          },
          loading: false,
        },
        download: {
          loading: false
        },
        upload: {
          url: null,
          highlight: false,
          allowed: ["image/jpeg", "image/png", "video/mp4", "video/quicktime"],
          max: 1000000,
          errors: [],
          success: null,
          loading: false
        },
        approve: {
          loading: false
        },
        reject: {
          loading: false
        },
        star: {
          loading: false
        },
      },
      ready: false,
      isInView: false
    }),

    computed: {
      waiting () {
        return _.some(this.controller, 'loading');
      },
      approving () {
        return this.controller.approve.loading||this.controller.reject.loading||this.controller.remove.loading||this.controller.star.loading;
      },
      empty () {
        return this.media!=null&&this.media.url==null;
      }
    },

    watch: {
      loading (b) {
        if (!b) {
          _.each(this.controller, (c,k) => {
            if (k=='upload') {
              this.controller.upload.url = null;
            }
            c.loading = b;
          })
        }
      },
      isInView (b) {
        if (this.zoomed) this.isInView = b = true;
        console.log('isInView', b, this.minHeight, this.media.url);
      }
    },

    methods: {
      open (b) {
        if (this.empty) {
          this.select()
        }else{
          if (this.controller.remove.loading) return;
          this.$emit('open', b, this.media.id)
        }
      },
      select () {
        this.$refs["upload-field"].click();
      },
      file (event, dropped) {
        const files = dropped
          ? event.dataTransfer.files
          : event.target.files;
        console.log("upload file", files);

        const $ = this;
        const allowed = _.filter(files, file => {
          return _.indexOf($.controller.upload.allowed, file.type)>=0;
        })
        if (_.size(allowed)>0) {
          $.upload(allowed);
        }
        const alert = _.size(files)-_.size(allowed);
        if (alert>0) {
          const message = this.$tc('general.invalid_file_format', alert);
          this.$emit('toast', message);
        }
      },
      upload (files) {
        console.log('url', files);
        this.controller.upload.loading = true;
        let data = _.map(files, file => {
          return _.merge(_.clone(this.media), { file, ...(this.media.id==null ? { status: 1 } : {}) });
        });
        data = this.media.id==null ? data : data[0];
        this.$emit('update', this.media.id, data);
      },
      highlight (b) {
        this.controller.upload.highlight = b;
        this.$emit("toggle", true);
      },
      download () {
        if (this.media.url!=null) {
          this.controller.remove.loading = true;
          this.$emit('download', this.media);
        }
      },
      approve (status) {
        const dict = [
          { action: 'reject', text: this.$tc('dashboard.gallery.rejected', 1) },
          { action: 'approve', text: this.$tc('dashboard.gallery.approved', 1) }, 
          { action: 'star', text: this.$tc('dashboard.gallery.starred', 1) }
        ];
        if (status===this.media.status) {
          this.$emit('toast', this.$t('dashboard.gallery.action_hint', { verb: dict[status].text }))
          return;
        }
        console.log('status', status);
        this.controller[dict[status].action].loading = true;
        const data = _.merge(_.clone(this.media), { status });
        this.$emit('update', this.media.id, data);
      },
      remove (confirm) {
        console.log('remove');
        if (confirm) {
          this.controller.remove.loading = true;
          this.$emit('remove', this.media.id);
          this.$emit('toast', false);
        }else{
          this.$emit('toast', $t('dashboard.gallery.remove_hint'))
        }
      },
      onReady (b) {
        console.log('GalleryCard ready', b);
        this.ready = b;
      },
      checkInView (entries, observer) {
        const isInView = entries[0].isIntersecting;
        console.log('checkInView', this.ready, isInView);
        this.isInView = isInView;
      },
      resize (width) {
        console.log('resize gallery card', width);
        this.$emit('resize', width);
      },

    },

    mounted () {
      if (this.zoomed) this.isInView = true;
    },

    components: {
      GalleryPlayer: () => import('@/components/GalleryPlayer'),
      MBtnLong: () => import('@/components/mBtnLong'),
    },
  }

</script>
        GalleryPlayer